import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Flex = makeShortcode("Flex");
const Icon = makeShortcode("Icon");
const Link = makeShortcode("Link");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "security-best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Security Best Practices`}<a parentName="h1" {...{
        "href": "#security-best-practices",
        "aria-label": "security best practices permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Kernel is about being careful, in all the many senses of that word. When it comes to code we write, that means safety first, then teamwork. Below, you will find the best security resources we know of to help you ensure that your contracts follow best practices and abide by the latest industry standards.`}</p>
    <h2 {...{
      "id": "tools",
      "style": {
        "position": "relative"
      }
    }}>{`Tools`}<a parentName="h2" {...{
        "href": "#tools",
        "aria-label": "tools permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Trail of Bits Toolbox`}</strong></p>
          <p>{`One of the most well-established and respected audit firm's very own toolbox for ensuring high security standards.`}</p>
          <p><em parentName="p">{`H/T Matt Solomon. Check out `}<Link to="https://medium.com/coinmonks/ethereum-security-analysis-tools-an-introduction-and-comparison-1096194e64d5" mdxType="Link">{`his article`}</Link>{` for more.`}</em></p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://github.com/crytic/eth-security-toolbox" mdxType="Button">
    Secure
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Detecting Not So Smart Contracts`}</strong></p>
          <p>{`Two useful repos for understanding the vulnerable patterns used by not-so-smart contracts, and for `}<Link to="https://github.com/crytic/slither/tree/master/tests/detectors" mdxType="Link">{`detecting various potential vulnerabilities`}</Link>{`.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://github.com/crytic/not-so-smart-contracts" mdxType="Button">
    Detect
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`MythX`}</strong></p>
          <p>{`Another useful Consensys tool that's easy to integrate with frameworks like Truffle especially. Find a useful code, among many other things, `}<Link to="https://www.notion.so/Module-2-Automated-Tooling-22b7b1513dd247d685616d55e27b6877" mdxType="Link">{`here`}</Link>{`.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://mythx.io/" mdxType="Button">
    Analyse
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`VS Code Solidity Auditor`}</strong></p>
          <p>{`A convenient and useful VS Code plugin to audit your smart contracts as you work.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://github.com/ConsenSys/vscode-solidity-auditor" mdxType="Button">
    Audit
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "praxis",
      "style": {
        "position": "relative"
      }
    }}>{`Praxis`}<a parentName="h2" {...{
        "href": "#praxis",
        "aria-label": "praxis permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Where practice and theory meet. This practices, lists, and methods indicated below are not quite tools in the sense of the section above, but they are very, very useful.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Secureum Checklist`}</strong></p>
          <p>{`A clear and concise checklist any serious developer or audit `}<em parentName="p">{`needs`}</em>{` for their work. Written by our own Rajeev Gopalakrishna.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://secureum.substack.com/p/smart-contract-security-101-secureum" mdxType="Button">
    Check
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Consensys Best Practices`}</strong></p>
          <p>{`A detailed set of instructions for writing safe code.`}</p>
          <p><em parentName="p">{`H/T Nazzareno Massari`}</em></p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://consensys.github.io/smart-contract-best-practices/" mdxType="Button">
    Learn
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Mutation Testing`}</strong></p>
          <p>{`An introduction to mutation testing from Security Track mentor Joran Honig. You can find a detailed explanation `}<Link to="https://github.com/JoranHonig/publications/blob/master/practical_mutation_testing_for_smart_contracts.pdf" mdxType="Link">{`here`}</Link>{`.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://joranhonig.nl/introduction-into-mutation/" mdxType="Button">
    Mutate
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "alerts",
      "style": {
        "position": "relative"
      }
    }}>{`Alerts`}<a parentName="h2" {...{
        "href": "#alerts",
        "aria-label": "alerts permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Security has as much to do with being informed as it does with managing risk. We recommend these publications to help you stay on top of the latest news, developments and insights across the industry (without having to spend all your days trawling CT - though that can have its use too).`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`BlockThreat`}</strong></p>
          <p>{`The latest in blockchain and cryptocurrency threat intelligence, vulnerabilities, security tools, and events.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://blockthreat.substack.com/" mdxType="Button">
    Learn
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`samczsun`}</strong></p>
          <p>{`The best blog to see how not to write smart contracts and learn about all the different ways your code could fall over in production.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://samczsun.com/" mdxType="Button">
    Tremble
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Rekt`}</strong></p>
          <p>{`The dark web of DeFi journalism. `}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://www.rekt.news" mdxType="Button">
    Learn
          </Button>
        </Flex>
      </Chocolate>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      